@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    width: 100%;
    height: 100%;
}
/*
body {
    height: 100%;
    width:100%;
    margin: 0;
    padding: 0;
}*/

#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}



.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel__container {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel__item {
    min-width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  
  .carousel__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 10;
  }
  
  .carousel__button--left {
    left: 10px;
  }
  
  .carousel__button--right {
    right: 10px;
  }
  
  .carousel__item img {
    width: 100%;
    height: auto;
  }